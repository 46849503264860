@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueHeavy.woff2') format('woff2'),
    url('/assets/fonts/UniNeueHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueHeavy.woff2') format('woff2'),
    url('/assets/fonts/UniNeueHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueRegular.woff2') format('woff2'),
    url('/assets/fonts/UniNeueRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueHeavy.woff2') format('woff2'),
    url('/assets/fonts/UniNeueHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueRegular.woff2') format('woff2'),
    url('/assets/fonts/UniNeueRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('/assets/fonts/UniNeueRegular.woff2') format('woff2'),
    url('/assets/fonts/UniNeueRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

