.show-my-wrapper {
    transition: all 0.3s;
    z-index: 1100;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    max-width: 800px;
    width: 100%;
    min-height: 170px;
    background: #f5f5f5;
    padding: 15px 25px;
    font-family: sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}
.my-popup-done {
    border: 8px solid #45ea45
}

.my-popup-error {
    border: 8px solid #ff0000
}
.show-my-wrapper p {
    font-weight: 700;
    font-size: 30px;
    margin: 0;
    margin-bottom: 15px
}
.show-my-wrapper span {
    margin: 0;
    font-size: 18px
}
.show-my-wrapper b {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: all ease .3s
}
.show-my-wrapper b:hover {
    background-color: #c5c5c5
}
.show-my-wrapper i {
    transform: rotateZ(45deg);
    width: 25px;
    height: 3px;
    background: #000;
    border-radius: 15px
}
.show-my-wrapper i::after {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    background: inherit;
    transform: rotateZ(90deg);
    border-radius: 15px
}
@media (max-width: 425px) {
    .show-my-wrapper {
        height: 101%;
        width: 101%;
        border: none !important;
    }

}