@media screen and (max-width: 1540px) {
  .menu {
    padding: 18px 60px 4px;
  }
  .header_links {
    &_wrapper {
      overflow-y: auto;
      margin-right: 24px;

      div {
        white-space: nowrap;
      }
    }
  }
  .bread {
    margin-left: 0;
  }
  .wrapper_small {
    max-width: 1230px;
  }
  .main_page {
    padding: 0;

    .content {
      max-width: 730px;
    }
  }
  .filter_content_elem .-price b, .filter_content_elem .-price s {
    font-size: 20px;
  }
}

// примениться к размеру от 991п до 1199п
@media screen and (max-width: 1199px) {
  body {
    font-size: 16px;
  }
  .bread ol li {
    font-size: calc(var(--index) * 0.9);
  }
  .wrapper, .wrapper_small {
    padding-left: 50px;
    padding-right: 50px;
  }
  .menu {
    padding: 10px 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    .phones {
      order: 2;

      a {
        font-size: calc(var(--index) * 1.2);
      }

      p {
        font-size: calc(var(--index) * 0.8);
      }

      .whats {
        width: 35px;
        height: 35px;
      }
    }

    &_options {
      a, div {
        span {
          font-size: calc(var(--index) * 0.8);
        }
      }
    }
  }
  .header_links {
    order: 3;
    width: 100%;
    justify-content: space-between;
    a {
      font-size: calc(var(--index) * .75);
    }
  }
  .main_page {
    padding: 0;
    margin-top: 130px;

    .content {
      max-width: 584px;
      .actions:before {
        width: 50px;
        height: 50px;
        left: 48%;
      }
    }
  }
  section {
    margin-bottom: 130px;
  }
  .h2 {
    line-height: 42px;

    br {
      display: none;
    }
  }
  .h3 {
    //font-size: 25px;
  }

  .sub_title {
    font-size: 20px;
  }
  .mb-80 {
    margin-bottom: 60px;
  }
  .filter {
    &_nav {
      gap: 30px 15px;

      &_elem {
        padding: 16px 9px 16px 14px;
      }

      .btn {
        padding: 0;
        min-width: auto;
      }
    }

    &_content {
      &_elem {
        padding: 16px;

        .-whats_app {
          right: -3px;
          bottom: 0;
        }
      }

    }
  }
  .filter_content_elem_name {
    font-size: 17px;
    line-height: 140%;
  }
  .filter_content_elem .-price .-old_price {
    margin: 0;
  }
  .akcii {
    .h2 {
      margin-bottom: 12px;
    }

    &_content {
      height: auto;

      .-info {
        a {
          font-size: calc(var(--index) * 0.9);
        }
      }
    }
  }
  .open_price {
    padding: 40px 44px;
    justify-content: space-between;

    &_text {
      width: 65%;
      flex-grow: 0;
    }

    &_img {
      max-width: 250px;
    }
  }
  .sotrudniki_elem > .-img img {
    max-height: 500px;
  }
  .quiz_content_elem .img img {
    height: 190px;
  }
  .photo_otchet {
    grid-gap: 50px;

    &_info {
      margin-top: 15px;
      margin-bottom: 0;

      div {
        flex-direction: column;
        justify-content: center;

        img {
          width: 50px;
        }

        p {
          margin-top: 20px;
          text-align: center;
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }
  .vakansii_elem {
    grid-template-columns: 1fr;
    justify-items: baseline;
  }
  .drenazh_system_elem .-img img {
    width: auto;
    height: 250px;
  }
  .primery_links_elem {
    padding: 0 20px;
    height: 100px;

    a {
      font-size: 15px;
    }
  }
  .sotrudniki_elem {
    .-info {
      span, p {
        font-size: 14px;
      }
    }

    &_kval .-sert a img {
      height: 72px;
    }
  }
  .material {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;

    &_elem {
      grid-gap: 7px;

      .-info {
        font-size: 16px;
      }

      &_name {
        font-size: 20px;
      }
    }
  }
  .question_elem .-info {
    font-size: 14px;
  }
  .footer {

    &_elem {
      &_like {
        font-size: 14px;
        padding: 20px;

        p {
          br {
            display: none;
          }
        }
      }

      &_contacts {
        &_wrapper {
          flex-wrap: wrap;
          gap: 0 20px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    &_prav {
      font-size: 12px;
    }
  }

  //  Страница контакты
  .kontakty {
    &_vk {
      width: 45px;
    }

    &_table table {
      td:first-child {
        padding-left: 160px;
      }
    }
  }

  // О КОМПАНИИ
  .o_kompanii_facts {
    grid-gap: 60px;
  }
  .o_kompanii_elem .-info {
    span {
      font-size: 55px;
    }

    p {
      font-size: 30px;
    }
  }
  .nagrada_color {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  //  ОТЗЫВЫ
  .otzyvy {
    gap: 5rem;
  }
  .garantii {
    &_elem_title {
      margin: 20px 0 8px;
    }

    span {
      font-size: 15px;
    }
  }
  .dostavka_content {
    grid-template-columns: 1fr 1.2fr;
    gap: 30px;

    .-img {
      width: 100%;
    }
  }
  .vakansii_title {
    text-align: center;
    line-height: 138%;
  }
  .vakansii_message:before {
    content: none;
  }
  //  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_gallery {
    grid-template-columns: repeat(3, 1fr);
  }
  //  СТРАНИЦА ТОВАРОВ
  .simple_form {
    padding: 40px 30px;

    button {
      font-size: 14px;
    }
  }

  // ПОРТФОЛИО
  .portfolio_content {
    img {
      max-height: 230px;
    }
  }

}

@media screen and (max-width: 991px) {
  .wrapper, .wrapper_small {
    padding-left: 20px;
    padding-right: 20px;
  }
  section {
    margin-bottom: 100px;
  }
  .btn {
    min-width: auto;
  }
  .menu {
    padding: 15px 10px;

    .phones {
      align-items: start;

      a {
        font-size: 18px;
        line-height: 30px;
      }

      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .header_links {
    padding: 12px 20px;
  }
  .header_links_tap_icon:before {
    width: 30px;
    height: 30px;
    top: -95%;
    left: -30px;
  }
  .main_page {
    .content {
      max-width: 540px;

      h1 {
        max-width: 441px;
      }

      .actions {
        margin-top: 40px;
        justify-content: space-between;

        a {
          width: 240px;

          &:before {
            margin-right: 5px;
          }
        }

        &:before {
          width: 50px;
          height: 50px;
          left: 43%;
        }
      }
    }

    .img {
      width: 43%;
    }
  }
  .header-down-info {
    font-size: 13px;
    line-height: 150%;
  }
  .h2 {
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
  }
  .sub_title {
    font-size: 16px;
    line-height: 30px;
  }
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .otzyvy {
    gap: 3rem;
    grid-template-columns: 1fr 2fr;
  }
  .filter_content_elem_options .kalkulyator::before {
    width: 40px;
    height: 40px;
    right: -12px;
    bottom: -17px;

  }
  .quiz {
    &_wrapper {
      padding: 35px 50px;
    }

    &_zagolovok p {
      font-size: 24px;
    }

    &_content {
      margin-top: 35px;
    }
  }
  .akcii_content {
    padding: calc(var(--index) * 1.2) calc(var(--index) * 3);
  }
  .drenazh_system {
    &_elem {
      width: 45%;
      width: calc(50% - 25px);
      margin-left: 12.5px;
      margin-right: 12.5px;
      margin-bottom: 30px;

      .yellow_line_text {
        font-size: 18px;
      }

      span {
        font-size: 14px;
      }
    }

    &_show_item {
      grid-template-columns: 1fr;
      gap: 30px 0;
      grid-template-areas: "info info" "form form" "text text";
    }
  }
  .razmery {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px 20px;
  }
  .price_table_title {
    padding: 15px 30px;
    font-size: 21px;

    .price_table_drop_btn {
      right: 50px;
    }
  }
  .price_table table tbody td {
    &:first-child {
      padding-left: 40px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }
  .question {
    gap: 20px;
    //grid-template-columns: 1fr;
    &_elem {
      height: 80px;
      padding: 20px;

      p {
        font-size: 14px;
        line-height: 137%;
      }
    }
  }
  .osusheniye p {
    font-size: 15px;
  }
  .footer {
    &_grid {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
    }

    &_elem_links {
      ul {
        display: flex;
        justify-content: space-between;

        li {
          text-align: left;
        }
      }
    }
  }
  .garantii_content {
    gap: 30px;

    .-img {
      width: 100%;
      max-width: 300px;

    }
  }
  .dostavka_content {
    grid-template-columns: 1fr;
    grid-gap: 50px;

    .-img {
      width: 100%;

      img {
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
  .dostavka_table {
    .-info {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  //  Страница контакты
  .kontakty {
    &_table table {
      td:first-child {
        padding-left: 100px;
      }
    }
  }
  //  документы
  .dokumenty {
    &_elem {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  //  О КОМПАНИИ
  .nagrada_color {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  //  СПОСОБЫ ОПЛАТЫ
  .sposoby_oplaty {
    &_bank {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  //  ОТЗЫВЫ
  //  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_deadline_elem {
    margin-right: 30px;
    padding: 20px 30px;
  }
  //  КАЛЬКУЛЯТОР
  .calc_elem_info {
    .-name {
      font-size: 22px;
    }

    .-sub_name {
      font-size: 16px;
      line-height: 26px;
    }
  }
  .filter {
    &_nav {
      gap: 30px 15px;

      &_elem {
        padding: 16px 9px 16px 14px;
      }

      .btn {
        padding: 0;
        min-width: auto;
      }
    }

    &_content {
      &_elem {
        .-whats_app {
          right: 20px;
          bottom: 30px;
        }
      }

    }
  }

  // Часто спрашивают
  .spros {
    .carousel__track {
      .carousel__slide {
        width: var(--carousel-slide-width, 50%);
      }
    }
  }

  .open_price {
    flex-wrap: wrap;

    &_text {
      margin-right: 0;

      form {
        margin: 50px auto 0;
      }
    }

    &_img {
      max-width: 208px;
    }
  }
  .nagrada {
    grid-gap: 20px;
  }
  .reiting {
    width: 100%;
  }
  .map {
    gap: 25px;

    &_img {
      width: 300px;
    }
  }
  // ПОРТФОЛИО
  .portfolio_content {
    img {
      max-height: 370px;
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper, .wrapper_small {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sub_title {
    line-height: 24px;
  }
  .menu {
    position: static;
    padding: 0;
    transform: none;
    margin-top: 55px;
    flex-wrap: wrap;

    &_options {
      flex-wrap: wrap;
      justify-content: center;
      gap: 13px;
      position: fixed;
      top: 4px;
      z-index: 11;
      padding: 5px 0;
      left: 19%;

      a, div {
        margin-right: 0;

        .count_cart {
          right: 8px;
        }

        span {
          display: none;
        }
        img {
          margin-bottom: 0;
        }
      }

    }

    &.yellow_line:before {
      content: none;
    }

    .burger {
      z-index: 11;
      position: fixed;
      left: 0;
      top: 0;
      padding: 10px 0;
      padding-left: 10px;
      width: 100%;
      display: block;
      background-color: #FFC93E;

      i {
        display: block;
        width: 24px;
        height: 18px;
        background-image: url("../images/icons/burger.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .address {
      display: none;
    }
    .phones {
      padding: 5px 0;
      z-index: 11;
      position: fixed;
      top: 0;
      right: 7px;
      margin-right: 0;

      a {
        font-size: 15px;
        line-height: 30px;
        color: #000;
      }

      p {
        display: none;
      }
    }
  }
  .header_logo {
    width: 40%;
    margin-left: 0;
  }
  .grid-2, .grid-3, .grid-4 {
    grid-template-columns: 1fr;
  }
  .flex {
    flex-wrap: wrap;
  }
  .main_grad {
    position: relative;
    height: auto;
    padding-bottom: 0;
  }
  .main_page {
    position: relative;
    margin-top: 20px;

    .content {
      width: 100%;
      max-width: 590px;

      h1 {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 23px;
        min-height: auto;
      }

      .actions {
        justify-content: center;
        position: absolute;
        z-index: 1;
        bottom: -60px;
        width: 100%;

        &:before {
          z-index: 2;
          height: 50px;
          width: 50px;
          bottom: -32px;
          left: auto;
          right: 30%;
        }

        a {
          font-size: 14px;
        }
      }
    }

    .img {
      z-index: 0;
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
  .bread ol li {
    font-size: 13px;
  }
  .header-down-info {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 120px auto 60px;
  }
  .btn {
    font-size: 16px;
    width: 100%;
  }
  .h2 {
    font-size: calc(var(--index) * 2.5);
    line-height: 35px;
  }
  .h3 {
    font-size: 18px;
    line-height: 33px;
  }
  .politica {
    line-height: 22px;
    font-size: 13px;
  }
  .mb-80 {
    margin-bottom: 40px;
  }
  .header_links {
    justify-content: space-between;
    border-radius: 0;
    z-index: 10;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #FFC93E;
    color: #000;
    top: -4px;
    left: -105%;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;

    &_tap_icon:before {
      content: none;
    }

    a {
      font-size: 16px;
      color: #000000;
      margin-left: 0;
      //margin-bottom: 10px;
      text-align: center;
    }

    p {
      text-align: center;
      display: block;
      width: 70%;
    }

    &_wrapper {
      height: 80%;

      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        white-space: normal;
      }
    }

  }
  .filter_content_elem .-whats_app {
    right: -2px;
    bottom: 0;
    width: 40px;
    height: 40px;
  }
  .filter_nav .btn {
    padding: 20px 40px;
  }
  .filter_content_elem .-desc {
    font-size: 14px;
    line-height: 150%;
  }
  .quiz {
    &_text {
      margin-left: 0;
    }

    &_wrapper {
      padding: 30px 20px;
    }

    &_zagolovok p {
      font-size: 20px;
      line-height: 30px;
    }

    &_content {
      margin-top: 30px;
      grid-gap: 10px;

      &_elem {
        .calc_img {
          border: 3px solid $yellow;
          padding: 16px;

          &:before {
            content: none;
          }
        }
      }
    }
  }
  .akcii {
    &_content {
      padding: calc(var(--index) * 2) calc(var(--index) * 3);
      max-height: 420px;
      overflow-y: auto;

      .-info {
        grid-template-columns: 1fr 1fr;

        a {
          font-size: 14px;
        }
      }
    }
  }
  .rassr {
    flex-wrap: wrap;
    gap: 30px;
  }
  .photo_otchet_info {
    width: 100%;
    flex-direction: column;
    align-items: start;

    div {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 15px;

      p {
        margin-top: 0;
        margin-left: 20px;
        text-align: left;
      }
    }
  }
  .drenazh_system {
    &_elem {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    &_show_item {
      grid-template-columns: 1fr;
      gap: 30px 0;
      grid-template-areas: "info" "form" "text";

      .-table table td {
        padding: 5px 7px;
      }

      .-images {
        grid-template-columns: 1fr 1fr;

        img {
          height: 137px;
        }
      }
    }
  }
  .drenazh_system_elem .-img img {
    width: auto;
  }
  .razmery {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .price_table {
    overflow-x: auto;
    width: 100%;
  }
  .price_table_title {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding-left: 20px;
    padding-right: 50px;

    .price_table_drop_btn {
      right: 25px;
      width: 19px;
    }
  }
  .price_table table tbody td {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 14px;
    border-right: 1px solid #DFDFDF;

    &:first-child {
      padding-left: 20px;
      padding-right: 5px;
    }

    &:last-child {
      padding-right: 20px;
      padding-left: 5px;
      border: none;
    }
  }
  .question_elem {
    height: auto;
    padding: 20px 10px 0;
    grid-template-columns: 1fr 30px;

    p {
      padding-right: 5px;
    }
  }
  .footer {
    &_grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &_elem {
      &:first-child {
        display: none;
      }

      &_contacts_wrapper {
        gap: 10px;
        flex-wrap: nowrap;
        flex-direction: column;
      }

      &_links {
        ul {
          display: block;
        }
      }
    }
  }

  .carousel__dots {
    .carousel__dot {
      width: 48px;
      height: 48px;
      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }

  // Часто спрашивают
  .spros {
    .carousel__track {
      .carousel__slide {
        width: var(--carousel-slide-width, 100%);
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  //  ОТЗЫВЫ
  .otzyvy {
    grid-template-columns: 1fr;
    gap: 2rem;

    &_elem {
      &_yandex_img {
        width: 100%;
      }

      &_img {
        justify-content: space-around;
        flex-wrap: wrap;

        a {
          margin-right: 0;

          img {
            display: inline-block;
            //margin-bottom: 10px;
          }
        }
      }
    }

    &_slider {
      width: 100%;
      .carousel__dots {
        display: flex;
      }

      .carousel__nav {
        display: none;
      }
    }
  }
  .photo_otchet_info div {
    flex-wrap: nowrap;
  }
  .dostavka_content .-img img {
    max-width: 100%;
  }
  .dostavka_table {
    .-info {
      grid-template-columns: 1fr;
      gap: 20px 0;

      a, b {
        text-align: left;
        font-size: 15px;
        padding-left: 32px;
      }
    }
  }
  .garantii_content .-img img {
    max-width: 100%;
  }
  //  ВАКАНСИИ
  .vakansii_message {
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    &:before {
      margin-right: 0;
      margin-bottom: 20px;
    }

    a {
      display: block;
    }
  }
  //  ДОКУМЕНТЫ
  .dokumenty {
    h1 {
      font-size: 26px;
    }

    &_title {
      margin-top: 45px;
      margin-bottom: 16px;
    }

    &_elem {
      grid-template-columns: 1fr;
    }
  }
  //  КОНТАКТЫ
  .kontakty {
    &_content {
      grid-template-columns: 1fr 1fr;
    }

    //  Страница контакты

    &_table {
        padding: 20px 10px;
        table {
            td {
                font-size: 14px;
            }
            td:first-child {
                padding-left: 5px;
            }
        }
    }

  }
  //  О КОМПАНИИ
  .nagrada {
    grid-gap: 35px;

    &_color {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  //  СПОСОБЫ ОПЛАТЫ
  .sposoby_oplaty {
    &_bank {
      grid-gap: 30px;

      img {
        margin: 0;
      }
    }
  }
  // СТРАНИЦА УСЛУГ
  .usluga {
    padding-bottom: 75px;

    &_content {
      position: relative;
      z-index: 0;
    }

    .usluga_img {
      z-index: -1;
      position: absolute;
    }
  }
  //  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_deadline {
    flex-wrap: wrap;

    &_elem {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  // КАЛЬКУЛЯТОР
  .calc_elem {
    grid-template-columns: 1fr;
    grid-gap: 22px;
    padding: 0 10px;

    .-name {
      margin-bottom: 15px;
    }
  }
  .calc_elem_range .-range_value {
    transform: translateX(-50%);
  }
  .calc_itog {
    align-items: start;
    //text-align: left;
    p {
      font-size: 24px;
      line-height: 30px;
      text-align: left;
    }

    b {
      font-size: 28px;
    }

    .-form {
      flex-wrap: wrap;
      justify-content: center;

      input {
        margin-bottom: 25px;
        margin-right: 0;
      }
    }
  }
  .simple_form button {
    font-size: 17px;
  }
  .open_price {
    gap: 40px;
    padding: 40px 25px;
    justify-content: center;

    &_text {
      order: 2;
      width: 100%;

      p {
        font-size: 28px;
        line-height: 32px;
      }
    }

    &_img {
      order: 1;
      position: static;
      max-width: 350px;
    }
  }
  .price_table_title {
    .h3 {
      font-size: 17px;
      line-height: 140%;
    }
  }
  .sotrudniki_elem_name {
    font-size: 20px;
  }
  .nagrada {
    grid-template-columns: 1fr 1fr;
  }
  .garantii_content {
    flex-wrap: wrap;

    &:last-child {
      .-img {
        order: 1;
      }

      .-text {
        order: 2;

        .h3 {
          font-size: 27px;
        }
      }
    }
  }
  .skidki_content {
    gap: 25px;
  }

  .-ocenka {
    flex-wrap: wrap;
  }
  .vakansii {
    flex-wrap: wrap;

    &_img {
      order: 1;
      width: 100%;
      max-width: 400px;
    }

    &_text {
      order: 2;
    }
  }
  .map {
    flex-wrap: wrap;
  }

  .form_catalog_submit input, .form_catalog_submit button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  // ПОРТФОЛИО
  .portfolio_content {
    img {
      max-height: none;
    }
  }
  //  Страница контакты
  .kontakty {
    &_content {
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
    }

    &_vk {
      position: static;
    }
  }
}

@media screen and (max-width: 575px) {
  .popup {
    padding: 0;

    .simple_form {
      padding: 30px 20px;
    }
  }

}